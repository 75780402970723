var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"card-body table-responsive p-0 mb-2"},[_c('table',{staticClass:"table table-bordered table-striped table-hover text-nowrap table-sm",staticStyle:{"font-size":"12px"}},[_vm._m(0),(_vm.preliquidaciones)?_c('tbody',_vm._l((_vm.preliquidaciones.data),function(preliquidacion){return _c('tr',{key:preliquidacion.id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(preliquidacion.id)+" ")]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:!preliquidacion.tep_preliquidacion_internos[0]
                  ? 'bg-danger'
                  : preliquidacion.tep_preliquidacion_internos[0].estado == 1
                  ? 'bg-info'
                  : preliquidacion.tep_preliquidacion_internos[0].estado == 2
                  ? 'bg-navy'
                  : preliquidacion.tep_preliquidacion_internos[0].estado == 3
                  ? 'bg-success'
                  : 'badge-gray'},[_vm._v(" "+_vm._s(preliquidacion.tep_preliquidacion_internos[0] ? preliquidacion.tep_preliquidacion_internos[0].nEstado : "No liquidado")+" ")])]),_c('td',[_vm._v(" "+_vm._s(preliquidacion.turno ? preliquidacion.turno.nombre : "")+" ")]),_c('td',[_vm._v(_vm._s(preliquidacion.fecha))]),_c('td',[_vm._v(" "+_vm._s(preliquidacion.vehiculo ? preliquidacion.vehiculo.placa : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(preliquidacion.conductor ? `${preliquidacion.conductor.nombres} ${preliquidacion.conductor.apellidos}` : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(preliquidacion.empresa ? preliquidacion.empresa.razon_social : "")+" ")]),_c('td',[_vm._v(_vm._s(preliquidacion.nTipoOperacion))]),_c('td',[_vm._v(_vm._s(preliquidacion.sitio.nombre))]),_c('td',[_vm._v(" "+_vm._s(preliquidacion.vehiculo ? preliquidacion.vehiculo.tipo_vehiculo.nombre : "")+" ")]),_c('td',{staticClass:"text-center"},[(preliquidacion.tep_preliquidacion_internos.length > 0)?_c('div',[(
                  preliquidacion.tep_preliquidacion_internos[0]
                    .conc_horas_trabajadas > 0
                )?_c('div',[_vm._v(" "+_vm._s(preliquidacion.tep_preliquidacion_internos[0] .conc_horas_trabajadas)+" ")]):_c('div',[_vm._v(" "+_vm._s(preliquidacion.tep_preliquidacion_internos[0] .horas_trabajadas)+" ")])]):_vm._e()]),_c('td',{staticClass:"text-center"},[(preliquidacion.tep_preliquidacion_internos.length > 0)?_c('div',[(
                  preliquidacion.tep_preliquidacion_internos[0]
                    .conc_km_recorridos > 0
                )?_c('div',[_vm._v(" "+_vm._s(preliquidacion.tep_preliquidacion_internos[0] .conc_km_recorridos)+" ")]):_c('div',[_vm._v(" "+_vm._s(preliquidacion.tep_preliquidacion_internos[0].km_recorridos)+" ")])]):_vm._e()]),_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"btn-group float-right"},[(
                  preliquidacion.tep_preliquidacion_internos.length > 0 &&
                  preliquidacion.tep_det_tarifa_interno_id
                )?_c('button',{staticClass:"btn bg-navy btn-sm",attrs:{"type":"button","data-toggle":"modal","data-target":"#Modal_preliquidacion"},on:{"click":function($event){return _vm.$parent.getModalData(preliquidacion)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                  _vm.$store.getters.can(
                    'tep.preliquidacionesInternos.calcularPreLiquidacionInterno'
                  ) &&
                  ((preliquidacion.tep_preliquidacion_internos.length > 0 &&
                    (preliquidacion.tep_preliquidacion_internos[0].estado ==
                      1 ||
                      preliquidacion.tep_preliquidacion_internos[0].estado ==
                        2)) ||
                    preliquidacion.tep_preliquidacion_internos.length == 0)
                )?_c('button',{staticClass:"btn bg-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.calcularPreLiquidacion(
                    preliquidacion.id,
                    _vm.preliquidaciones.current_page
                  )}}},[_c('i',{staticClass:"fas fa-sync-alt"})]):_vm._e()])])])}),0):_vm._e()])]),_c('div',{staticClass:"card-footer"},[(_vm.preliquidaciones.total)?_c('div',{staticClass:"float-left"},[_c('p',[_vm._v(" Mostrando del "),_c('b',[_vm._v(_vm._s(_vm.preliquidaciones.from))]),_vm._v(" al "),_c('b',[_vm._v(_vm._s(_vm.preliquidaciones.to))]),_vm._v(" de un total: "),_c('b',[_vm._v(_vm._s(_vm.preliquidaciones.total))]),_vm._v(" Registros ")])]):_c('div',{staticClass:"float-left"},[_vm._m(1)]),_c('pagination',{attrs:{"data":_vm.preliquidaciones,"limit":5,"align":"right"},on:{"pagination-change-page":_vm.$parent.getIndex}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Turno")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Turno")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Vehículo")]),_c('th',[_vm._v("Conductor")]),_c('th',[_vm._v("Transportadora")]),_c('th',[_vm._v("Operación")]),_c('th',[_vm._v("Sitio")]),_c('th',[_vm._v("Tipo Vehículo")]),_c('th',[_vm._v("Horas Trabajadas")]),_c('th',[_vm._v("Km Recorridos")]),_c('th')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"badge badge-danger"},[_vm._v(" No hay registros para mostrar ")])])
}]

export { render, staticRenderFns }