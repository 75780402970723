<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Preliquidaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Inernos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Preliquidaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <h5>Filtros</h5>
                  </div>
                  <div class="col-6">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-primary btn-sm"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-tep-preliquidacion-export"
                        v-if="
                          $store.getters.can(
                            'tep.preliquidacionesInternos.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>Id</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Razon social"
                      label="razon_social"
                      :options="listasForms.transportadoras"
                      @input="seleccionarTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo de Operación</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_operacion"
                      placeholder="Tipo Operación"
                      label="descripcion"
                      :options="listasForms.tipos_operaciones"
                      @input="seleccionarTipoOperacion()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="operacion"
                      placeholder="Sitio"
                      label="nombre"
                      :options="listasForms.operaciones"
                      @input="seleccionarOperacion()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="estado">Estado</label>
                    <select
                      id="estado"
                      v-model="filtros.estado"
                      class="form-control form-control-sm"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                      <option value="5"> No liquidado</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo de Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_vehiculo"
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      :options="listasForms.tipos_vehiculos"
                      @input="seleccionarTipoVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="vehiculo"
                      placeholder="Placa"
                      label="placa"
                      :options="listasForms.vehiculos"
                      @input="seleccionarVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Conductor</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="conductor"
                      placeholder="Conductror"
                      label="nConductor"
                      :options="listasForms.conductores"
                      @input="seleccionarConductor()"
                    ></v-select>
                  </div>
                  <div class="col-md-4">
                    <label>Turno</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="select_turno"
                      placeholder="Turno"
                      label="nombre"
                      :options="listasForms.turnos"
                      @input="seleccionarTurno()"
                    ></v-select>
                  </div>
                  <div class="col-md-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                  <div class="col-md-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header border-0">
                <div class="row">
                  <div class="col-6">
                    <h5>Preliquidaciones</h5>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Calendario"
                      >Calendario</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#Tabla"
                      >Tabla</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade" id="Calendario">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-12" style="min-height: 500px">
                          <FullCalendar
                            v-if="
                              filtros.fecha_ini != null &&
                                filtros.fecha_fin != null &&
                                data_calendario != null
                            "
                            :options="calendarOptions"
                            ref="fullCalendar"
                          />
                          <div v-else>
                            <h4>
                              Debe seleccionar un rango de fecha para ver
                              resultados...
                            </h4>
                          </div>
                        </div>
                        <div style="display: none">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#Modal_preliquidacion"
                            ref="modal_preliquidacion"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade active show" id="Tabla">
                    <TepPreliquidacionInternoTabla
                      ref="TepPreliquidacionInternoTabla"
                      v-if="preliquidaciones"
                    ></TepPreliquidacionInternoTabla>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Modal de detalle de la preliquidación -->
        <div class="modal fade" id="Modal_preliquidacion">
          <div class="modal-dialog modal-xl" v-if="modalData">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ modalTitle }}</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0 mt-1">
                <ul class="nav nav-tabs" id="tab_preliquidacion" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#Detalle"
                      >Detalle</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Odometro"
                      >Odómetro</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#Trazabilidad"
                      @click="getTraza(), showGeocercas()"
                      >Trazabilidad</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Conciliacion"
                      >Conciliación</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      ($store.getters.can(
                        'tep.preliquidacionesInternos.firmaFrontera'
                      ) ||
                        $store.getters.can(
                          'tep.preliquidacionesInternos.firmaTransportadora'
                        )) &&
                        modalData.tep_preliquidacion_internos[0]
                          .conc_horas_extra &&
                        modalData.tep_preliquidacion_internos[0].conc_km_add
                    "
                  >
                    <a class="nav-link" data-toggle="tab" href="#Firmas"
                      >Firmas</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Inspeccion"
                      >Inspección</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="tab_preliquidacionContent">
                  <!-- Pestaña Detalle -->
                  <div class="tab-pane fade active show" id="Detalle">
                    <!-- Detalle -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="ribbon-wrapper ribbon-lg">
                              <div
                                class="ribbon"
                                :class="
                                  !modalData.tep_preliquidacion_internos[0]
                                    ? 'bg-danger'
                                    : modalData.tep_preliquidacion_internos[0]
                                        .estado == 1
                                    ? 'bg-info'
                                    : modalData.tep_preliquidacion_internos[0]
                                        .estado == 2
                                    ? 'bg-navy'
                                    : modalData.tep_preliquidacion_internos[0]
                                        .estado == 3
                                    ? 'bg-success'
                                    : 'badge-gray'
                                "
                              >
                                <small>
                                  {{
                                    modalData.tep_preliquidacion_internos[0]
                                      ? modalData.tep_preliquidacion_internos[0]
                                          .nEstado
                                      : "No liquidado"
                                  }}
                                </small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.id }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Fecha</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_ini }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Inicio Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_fin }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Fin Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.nTipoOperacion }}
                                    <b>({{ modalData.nArea }})</b>
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Tipo de Operación</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.turno.nombre }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.sitio.nombre }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Operación</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.tipo_vehiculo.nombre }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Tipo vehiculo</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.empresa.razon_social }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Empresa</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    :class="
                                      modalData.estado_inspeccion == 1
                                        ? 'text-success'
                                        : modalData.estado_inspeccion == 2
                                        ? 'text-success'
                                        : 'text-danger'
                                    "
                                  >
                                    {{ modalData.nEstadoInspeccion }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Estado Inspección</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Vehículo Conductor -->
                    <div class="container">
                      <div class="row justify-content-md-center">
                        <div class="col-md-12">
                          <div class="card card-dark">
                            <div
                              class="card-header"
                              v-if="modalData.tipo_operacion != 3"
                            >
                              <h3 class="card-title">Vehículo y Conductor</h3>
                            </div>
                            <div class="card-header" v-else>
                              <h3 class="card-title">Vehículo</h3>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="
                                    col-sm-6
                                    d-flex
                                    align-items-stretch
                                    flex-column
                                  "
                                >
                                  <div class="card bg-white d-flex flex-fill">
                                    <div
                                      class="
                                        card-header
                                        text-muted
                                        border-bottom-0
                                      "
                                    >
                                      Vehículo
                                    </div>
                                    <div class="card-body pt-0">
                                      <div class="row">
                                        <div
                                          :class="
                                            modalData.vehiculo.link_fotografia
                                              ? 'col-md-7'
                                              : 'col-md-10'
                                          "
                                        >
                                          <h2 class="lead">
                                            <b>{{
                                              modalData.vehiculo.placa
                                            }}</b>
                                          </h2>
                                          <ul
                                            class="ml-4 mb-0 fa-ul text-muted"
                                          >
                                            <li class="small">
                                              <span class="fa-li"
                                                ><i class="fas fa-lg fa-car"></i
                                              ></span>
                                              Tipo Vehículo:
                                              {{
                                                modalData.vehiculo.tipo_vehiculo
                                                  .nombre
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          class="col-md-5 text-center"
                                          v-if="
                                            modalData.vehiculo.link_fotografia
                                          "
                                        >
                                          <img
                                            :src="
                                              uri_docs +
                                                modalData.vehiculo
                                                  .link_fotografia
                                            "
                                            alt="Vehículo"
                                            class="
                                              img-bordered img-rounded img-fluid
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="
                                    col-sm-6
                                    d-flex
                                    align-items-stretch
                                    flex-column
                                  "
                                >
                                  <div
                                    class="card bg-white d-flex flex-fill"
                                    v-if="modalData.tipo_operacion != 3"
                                  >
                                    <div
                                      class="
                                        card-header
                                        text-muted
                                        border-bottom-0
                                      "
                                    >
                                      Conductor
                                    </div>
                                    <div class="card-body pt-0">
                                      <div class="row">
                                        <div
                                          :class="
                                            modalData.conductor.link_fotografia
                                              ? 'col-md-7'
                                              : 'col-md-10'
                                          "
                                        >
                                          <h2 class="lead">
                                            <b>
                                              {{ modalData.conductor.nombres }}
                                              {{
                                                modalData.conductor.apellidos
                                              }}
                                            </b>
                                          </h2>
                                          <p class="text-muted text-sm">
                                            <b>Documento: </b>
                                            {{
                                              modalData.conductor
                                                .numero_documento
                                            }}
                                          </p>
                                          <ul
                                            class="ml-4 mb-0 fa-ul text-muted"
                                          >
                                            <li class="small">
                                              <span class="fa-li"
                                                ><i
                                                  class="fas fa-lg fa-envelope"
                                                ></i
                                              ></span>
                                              E-mail:
                                              {{ modalData.conductor.email }}
                                            </li>
                                            <li class="small">
                                              <span class="fa-li"
                                                ><i
                                                  class="fas fa-lg fa-mobile"
                                                ></i
                                              ></span>
                                              Celular Principal:
                                              {{
                                                modalData.conductor
                                                  .celular_principal
                                              }}
                                            </li>
                                            <li class="small">
                                              <span class="fa-li"
                                                ><i
                                                  class="fas fa-lg fa-phone"
                                                ></i
                                              ></span>
                                              Celular Alterno:
                                              {{
                                                modalData.conductor
                                                  .celular_alterno
                                              }}
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          class="col-md-5 text-center"
                                          v-if="
                                            modalData.conductor.link_fotografia
                                          "
                                        >
                                          <img
                                            :src="
                                              uri_docs +
                                                modalData.conductor
                                                  .link_fotografia
                                            "
                                            alt="Conductor"
                                            class="
                                              img-bordered img-rounded img-fluid
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Odómetro -->
                  <div class="tab-pane fade" id="Odometro">
                    <div class="container">
                      <div class="row justify-content-md-center">
                        <div class="col-md-12">
                          <div class="position-relative p-3 m-2 bg-light">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="
                                      col-sm-6
                                      d-flex
                                      align-items-stretch
                                      flex-column
                                    "
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                          card-header
                                          text-muted
                                          border-bottom-0
                                        "
                                      >
                                        Odómetro Inicial
                                      </div>
                                      <div class="card-body pt-0">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h3 class="lead">
                                              <b>{{
                                                modalData.odometro_ini
                                              }}</b>
                                            </h3>
                                          </div>
                                          <div class="col-md-8 text-center">
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.link_odometro_ini
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.link_odometro_ini
                                              "
                                              alt="Odómetro Inicial"
                                              class="
                                                img-bordered
                                                img-rounded
                                                img-fluid
                                              "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      col-md-6
                                      d-flex
                                      align-items-stretch
                                      flex-column
                                    "
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                          card-header
                                          text-muted
                                          border-bottom-0
                                        "
                                      >
                                        Odómetro Final
                                      </div>
                                      <div class="card-body pt-0">
                                        <div class="row">
                                          <div class="col-md-5">
                                            <h3 class="lead">
                                              <b>{{
                                                modalData.odometro_fin
                                              }}</b>
                                            </h3>
                                          </div>
                                          <div class="col-md-8 text-center">
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.link_odometro_fin
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.link_odometro_fin
                                              "
                                              alt="Odómetro Final"
                                              class="
                                                img-bordered
                                                img-rounded
                                                img-fluid
                                              "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Trazabilidad -->
                  <div
                    class="tab-pane fade"
                    id="Trazabilidad"
                    v-if="preliquidacion_id"
                  >
                    <!-- contenido del mapa -->
                    <div class="card-body" v-if="!mensaje_error">
                      <div class="row">
                        <div class="col-md-8">
                          <gmap-map
                            :center="center"
                            :zoom="zoom"
                            style="width: 100%; height: 650px"
                          >
                            <gmap-marker
                              :key="index"
                              v-for="(m, index) in markers"
                              :position="m.position"
                              :icon="m.icon"
                              :animation="2"
                              @click="toggleInfoWindow(m, index, m.info)"
                            ></gmap-marker>
                            <gmap-marker
                              :key="index"
                              v-for="(n, index) in markers_viaje"
                              :position="n.position"
                              :icon="n.icon"
                              :animation="3"
                              @click="toggleInfoWindow(n, index, n.info)"
                            ></gmap-marker>
                            <gmap-info-window
                              :options="infoOptions"
                              :position="infoWindowPos"
                              :opened="infoWinOpen"
                              @closeclick="infoWinOpen = false"
                            >
                              <div v-html="infoContent"></div>
                            </gmap-info-window>
                            <gmap-polygon
                              v-for="poligono in poligonos"
                              :options="{ fillColor: poligono.color }"
                              :key="poligono.id"
                              :paths="poligono.path"
                              :editable="false"
                              :draggable="false"
                              @click="
                                toggleInfoWindowPoli(
                                  poligono,
                                  poligono.id,
                                  poligono.info
                                )
                              "
                            />
                            <gmap-circle
                              v-for="circular in circunferencias"
                              :key="circular.id"
                              :center="circular.center"
                              :radius="circular.radius"
                              :info="circular.info"
                              @click="
                                toggleInfoWindowCir(
                                  circular,
                                  circular.id,
                                  circular.info
                                )
                              "
                            ></gmap-circle>
                          </gmap-map>
                        </div>
                        <div class="col-md-4">
                          <!-- Origen y destino -->
                          <table
                            class="table table-sm table-striped table-bordered"
                          >
                            <thead class="thead-dark">
                              <tr>
                                <th colspan="1" class="text-center"></th>
                                <th colspan="1" class="text-center">
                                  Longitud
                                </th>
                                <th colspan="1" class="text-center">Latitud</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-center">
                                  <span class="badge bg-olive"
                                    >Coordenadas Iniciales</span
                                  >
                                </td>
                                <td>
                                  {{ modalData.lon_ini_vehiculo }}
                                </td>

                                <td>
                                  {{ modalData.lat_ini_vehiculo }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <span class="badge bg-pink"
                                    >Coordenadas Finales</span
                                  >
                                </td>
                                <td>
                                  {{ modalData.lon_fin_vehiculo }}
                                </td>
                                <td>
                                  {{ modalData.lat_fin_vehiculo }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Mensaje de no trazabilidad -->
                    <div class="card-body" v-else>
                      <div class="info-box bg-gradient-warning">
                        <span class="info-box-icon"
                          ><i class="fas fa-exclamation-triangle"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text"
                            >No hay trazabilidad de este vehículo</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Conciliación -->
                  <div class="tab-pane fade" id="Conciliacion">
                    <!-- Detalle -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="row">
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.id }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_ini }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Inicio Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_fin }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Fin Turno</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  $store.getters.can(
                                    'tep.preliquidacionesInternos.conciliarPreliquidacion'
                                  )
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    <div
                                      v-if="
                                        modalData.tep_preliquidacion_internos[0]
                                          .conc_km_recorridos > 0
                                      "
                                    >
                                      {{
                                        modalData.tep_preliquidacion_internos[0]
                                          .conc_km_recorridos
                                      }}
                                    </div>
                                    <div v-else>
                                      {{
                                        modalData.tep_preliquidacion_internos[0]
                                          .km_recorridos
                                      }}
                                    </div>
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Km Recorridos</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  $store.getters.can(
                                    'tep.preliquidacionesInternos.conciliarPreliquidacion'
                                  )
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    <div
                                      v-if="
                                        modalData.tep_preliquidacion_internos[0]
                                          .conc_horas_trabajadas
                                      "
                                    >
                                      {{
                                        modalData.tep_preliquidacion_internos[0]
                                          .conc_horas_trabajadas
                                      }}
                                    </div>
                                    <div v-else>
                                      {{
                                        modalData.tep_preliquidacion_internos[0]
                                          .horas_trabajadas
                                      }}
                                    </div>
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_internos[0]
                                        .val_turno
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Turno</span
                                  >
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_internos[0]
                                        .val_hora_extra
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Hora Extra</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_internos[0]
                                        .val_km_add
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Km. Adicional</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Formulario -->
                    <div class="panel-body p-3">
                      <div class="row">
                        <div class="form-group col-md-3">
                          <label>Horas Extra</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_horas_extra"
                            :class="
                              $v.form.conc_horas_extra.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_internos[0]
                                .user_firma_frontera_id &&
                                !$store.getters.can(
                                  'tep.preliquidacionesInternos.conciliarPreliquidacion'
                                )
                            "
                            @input="calcCostoHorasExtra()"
                          />
                          <span
                            >Valor Horas Extra:
                            <strong
                              >${{ form.conc_costo_horas_extra }}</strong
                            ></span
                          >
                        </div>
                        <div class="form-group col-md-3">
                          <label>Kilómetros Extra</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_km_add"
                            :class="
                              $v.form.conc_km_add.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_internos[0]
                                .user_firma_frontera_id &&
                                !$store.getters.can(
                                  'tep.preliquidacionesInternos.conciliarPreliquidacion'
                                )
                            "
                            @input="calcCostoKmAdicionales()"
                          />
                          <span
                            >Valor Kilómetros Extra:
                            <strong>${{ form.conc_costo_km_add }}</strong></span
                          >
                        </div>
                        <div class="form-group col-md-1 text-center">
                          <div
                            v-if="
                              modalData.tep_det_tarifa_interno.tarifa_interno
                                .contrato.pernocte > 0
                            "
                          >
                            <label>Pernocte</label>
                            <input
                              type="checkbox"
                              class="form-control form-control-sm p-0"
                              v-model="form.pernocte"
                              @change="
                                setCostoPernocte(
                                  form.pernocte,
                                  modalData.tep_det_tarifa_interno
                                    .tarifa_interno.contrato.pernocte
                                )
                              "
                            />
                            <strong>${{ form.costo_pernocte }}</strong>
                          </div>
                        </div>
                        <div class="form-group col-md-1 text-center">
                          <label>Adicional</label>
                          <input
                            type="checkbox"
                            class="form-control form-control-sm p-0"
                            v-model="form.adicional"
                          />
                        </div>
                        <div class="form-group col-md-1 text-center mt-4">
                          <button
                            type="button"
                            class="btn btn-primary"
                            v-if="
                              !$v.form.$invalid &&
                                !modalData.tep_preliquidacion_internos[0]
                                  .user_firma_frontera_id &&
                                $store.getters.can(
                                  'tep.preliquidacionesInternos.conciliarPreliquidacion'
                                )
                            "
                            @click="validaConciliacion(modalData)"
                          >
                            Conciliar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Firmas -->
                  <div
                    class="tab-pane fade"
                    id="Firmas"
                    v-if="
                      ($store.getters.can('tep.preliquidacionesInternos.firmaFrontera') ||
                        $store.getters.can('tep.preliquidacionesInternos.firmaTransportadora')) &&
                        modalData.tep_preliquidacion_internos[0].conc_horas_extra &&
                        modalData.tep_preliquidacion_internos[0].conc_km_add
                    "
                  >
                    <div class="panel-body p-3">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <h5>Firma Frontera</h5>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_internos[0].user_firma_frontera_id &&
                              $store.getters.can('tep.preliquidacionesInternos.firmaFrontera')
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small>
                                  <strong>
                                    {{
                                      $store.getters.getAuthAzure 
                                      ? `La sesión esta iniciada con Azure.`
                                      : `Para firmar la preliquidación por favor digite su clave.`
                                    }}
                                  </strong>
                                </small>
                              </div>
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!$store.getters.getAuthAzure"
                            >
                              <label for="sign_password">Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.pass"
                                :class="
                                  $v.firmas.fronteraForm.pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!$store.getters.getAuthAzure"
                            >
                              <label for="re_sign_password"
                                >Confirme Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.re_pass"
                                :class="
                                  $v.firmas.fronteraForm.re_pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label for="observaciones">Observaciones</label
                              ><textarea
                                vtype="date"
                                rows="2"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.obs_firma_frontera"
                                :class="
                                  $v.firmas.fronteraForm.obs_firma_frontera
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                              <label for="documentos">Documento</label>
                              <div class="row">
                                <div class="col-lg-12">
                                  <input
                                    type="file"
                                    class="form-control-file"
                                    ref="file"
                                    id="btnFile"
                                    @change="getFile"
                                    accept="application/msword,application/pdf,application/rtf,image/*"
                                  />
                                </div>
                              </div>
                            </div>
                            <div 
                              class="form-group col-md-12"
                              v-if="documento"
                            >
                              <figure>
                                <div class="button-container">
                                  <img
                                    :src="documento"
                                    alt="Documento"
                                    class="img-bordered img-rounded img-fluid"
                                  />
                                </div>
                              </figure>
                            </div>
                            <div class="form-group col-md-12">
                              <button
                                type="button"
                                title="Guardar firma"
                                data-toggle="tooltip"
                                class="btn btn-sm bg-success"
                                v-if="!$v.firmas.fronteraForm.$invalid"
                                @click="saveFirma()"
                              >
                                <i class="fas fa-signature"></i>
                                <div>
                                  <span
                                    ><small>Aprobar Preliquidación</small></span
                                  >
                                </div>
                              </button>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !$store.getters.can(
                                'tep.preliquidacionesInternos.firmaFrontera'
                              )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de
                                    Frontera.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              modalData.tep_preliquidacion_internos[0]
                                .user_firma_frontera_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <p>
                                Firmado por: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_internos[0]
                                    .usuario_frontera.name
                                }}</strong>
                              </p>
                              <p>
                                Fecha: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_internos[0]
                                    .fecha_firma_frontera
                                }}</strong>
                              </p>
                              <p>
                                Observación: <br />
                                {{
                                  modalData.tep_preliquidacion_internos[0]
                                    .obs_firma_frontera
                                }}
                              </p>
                              <div class="button-container">
                                <img
                                  :src="documento"
                                  alt="Documento"
                                  class="img-bordered img-rounded img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <h5>Firma Transportadora</h5>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_internos[0]
                                .user_firma_trans_id &&
                                modalData.tep_preliquidacion_internos[0]
                                  .user_firma_frontera_id &&
                                $store.getters.can(
                                  'tep.preliquidacionesInternos.firmaTransportadora'
                                )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >Para firmar la preliquidación por favor
                                    digite su clave.</strong
                                  ></small
                                >
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="sign_password">Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.transportadoraForm.pass"
                                :class="
                                  $v.firmas.transportadoraForm.pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label for="re_sign_password"
                                >Confirme Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.transportadoraForm.re_pass"
                                :class="
                                  $v.firmas.transportadoraForm.re_pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label for="observaciones">Observaciones</label
                              ><textarea
                                vtype="date"
                                rows="2"
                                class="form-control form-control-sm"
                                v-model="
                                  firmas.transportadoraForm.obs_firma_trans
                                "
                                :class="
                                  $v.firmas.transportadoraForm.obs_firma_trans
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                              <button
                                type="button"
                                title="Guardar firma"
                                data-toggle="tooltip"
                                class="btn btn-sm bg-success"
                                v-if="!$v.firmas.transportadoraForm.$invalid"
                                @click="saveFirma()"
                              >
                                <i class="fas fa-signature"></i>
                                <div>
                                  <span
                                    ><small>Aprobar Preliquidación</small></span
                                  >
                                </div>
                              </button>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_internos[0]
                                .user_firma_frontera_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de
                                    Frontera.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !$store.getters.can(
                                'tep.preliquidacionesInternos.firmaTransportadora'
                              )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de la
                                    Transportadora.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              modalData.tep_preliquidacion_internos[0]
                                .user_firma_trans_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <p>
                                Firmado por: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_internos[0]
                                    .usuario_transportadora.name
                                }}</strong>
                              </p>
                              <p>
                                Fecha: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_internos[0]
                                    .fecha_firma_trans
                                }}</strong>
                              </p>
                              <p>
                                Observación: <br />
                                {{
                                  modalData.tep_preliquidacion_internos[0]
                                    .obs_firma_trans
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña inspeccion -->
                  <div class="tab-pane" id="Inspeccion">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div
                              class="ribbon-wrapper ribbon-lg"
                              v-if="
                                modalData.inspeccion !== null &&
                                  modalData.inspeccion !== undefined
                              "
                            >
                              <div
                                class="ribbon"
                                :class="[
                                  modalData.inspeccion.estado == 1
                                    ? 'badge-secondary'
                                    : modalData.inspeccion.estado == 2
                                    ? 'badge-success'
                                    : modalData.inspeccion.estado == 3
                                    ? 'badge-success'
                                    : modalData.inspeccion.estado == 4
                                    ? 'badge-danger'
                                    : modalData.inspeccion.estado == 5
                                    ? 'bg-olive'
                                    : 'badge-success',
                                ]"
                              >
                                <small>{{ modalData.nEstadoInspeccion }}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{ modalData.inspeccion.id }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    ># Inspección</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-1">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.id }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    ># Turno</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{
                                      modalData.inspeccion.solicitud
                                        ? modalData.inspeccion.solicitud.id
                                        : "Preop"
                                    }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Solicitud</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{ modalData.inspeccion.fecha_inicio }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Fecha</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{
                                      modalData.inspeccion.formato_inspeccion
                                        .nombre
                                    }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Formato</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{ modalData.inspeccion.sitio.nombre }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Sitio</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{
                                      modalData.inspeccion.empresa.razon_social
                                    }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Empresa</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{ modalData.inspeccion.vehiculo.placa }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Vehiculo</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined &&
                                        modalData.tipo_operacion != 3
                                    "
                                  >
                                    {{ modalData.inspeccion.conductor.nombres }}
                                    {{
                                      modalData.inspeccion.conductor.apellidos
                                    }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Conductor</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <h5
                                    class="description-header"
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    {{
                                      modalData.inspeccion.estado_documento == 1
                                        ? "Borrador"
                                        : "Definitivo"
                                    }}
                                  </h5>
                                  <h5 v-else></h5>
                                  <span class="description-text text-secondary"
                                    >Estado documento</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="description-block">
                                  <div
                                    v-if="
                                      modalData.inspeccion !== null &&
                                        modalData.inspeccion !== undefined
                                    "
                                  >
                                    <div
                                      v-if="
                                        modalData.inspeccion.estado_documento ==
                                          2
                                      "
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-sm bg-danger"
                                        v-if="
                                          $store.getters.can(
                                            'tep.inspecciones.verPdf'
                                          )
                                        "
                                        @click="verPDF(modalData.inspeccion.id)"
                                      >
                                        <i class="fas fa-file-pdf"></i>
                                      </button>
                                    </div>
                                    <h5 v-else></h5>
                                  </div>
                                  <div v-else>
                                    <h5></h5>
                                  </div>

                                  <span class="description-text text-secondary"
                                    >Estado documento</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TepPreliquidacionInternoExport ref="TepPreliquidacionInternoExport" />
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";
import TepPreliquidacionInternoTabla from "./TepPreliquidacionInternoTabla";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeline from "@fullcalendar/timeline";
import "@fullcalendar/core/vdom";
import TepPreliquidacionInternoExport from "./TepPreliquidacionInternoExport";
/*

*/
export default {
  name: "TepPreliquidacionInternoIndex",
  components: {
    Loading,
    vSelect,
    TepPreliquidacionInternoTabla,
    FullCalendar,
    TepPreliquidacionInternoExport,
  },
  data() {
    return {
      actualPage: null,
      fileDoc: null,
      cargando: false,
      documento: null,
      preliquidaciones: null,
      data_calendario: null,
      transportadora: null,
      tipo_vehiculo: null,
      select_turno: null,
      vehiculo: null,
      conductor: null,
      tipo_operacion: null,
      operacion: null,
      fecha_ini: null,
      fecha_fin: null,
      // datos del calendaro de Camilo
      calendarOptions: {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        initialView: "resourceTimelineDay",
        locale: esLocale,
        timeZone: "America/Bogota",
        editable: true,
        navLinks: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineWeek,dayGridMonth,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          resourceTimelinePlugin,
          listGridPlugin,
          timeline,
        ],
        weekends: true,
        resourceAreaColumns: [
          {
            group: true,
            field: "campo",
            headerContent: "Campo",
          },
          {
            field: "operacion",
            headerContent: "Operación",
          },
          {
            field: "vehiculo",
            headerContent: "Vehiculo",
          },
        ],
        resources: [],
        eventClick: this.verDet,
      },
      // datos para el mapa
      preliquidacion_id: null,
      traza_viaje: null,
      center: {},
      zoom: 10,
      markers: [],
      markers_viaje: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      mensaje_error: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      locations: {},
      poligonos: [],
      circunferencias: [],
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
      },
      modalTitle: null,
      modalData: null,
      form: {
        id: null,
        val_km_add: null,
        val_hora_extra: null,
        horas_extra: null,
        costo_horas_extra: null,
        conc_km_recorridos: null,
        conc_horas_trabajadas: null,
        km_add: null,
        costo_km_add: null,
        conc_horas_extra: null,
        conc_costo_horas_extra: null,
        conc_km_add: null,
        conc_costo_km_add: null,
        pernocte: false,
        adicional: false,
      },
      firmas: {
        fronteraForm: {
          id: null,
          obs_firma_frontera: null,
          pass: null,
          re_pass: null,
        },
        transportadoraForm: {
          id: null,
          obs_firma_trans: null,
          pass: null,
          re_pass: null,
        },
      },
      listasForms: {
        transportadoras: [],
        tipos_vehiculos: [],
        vehiculos: [],
        conductores: [],
        tipos_operaciones: [],
        operaciones: [],
        turnos: [],
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    let firm_front = {};
    if (!this.$store.getters.getAuthAzure) {
      firm_front = {
        id: {
          required,
        },
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        obs_firma_frontera: {
          required,
        },
      };
    } else {
      firm_front = {
        id: {
          required,
        },
        obs_firma_frontera: {
          required,
        },
      };
    }

    return {
      form: {
        conc_horas_extra: {
          required,
        },
        conc_costo_horas_extra: {
          required,
        },
        conc_km_add: {
          required,
        },
        conc_costo_km_add: {
          required,
        },
      },
      firmas: {
        fronteraForm: firm_front,
        transportadoraForm: {
          id: {
            required,
          },
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          obs_firma_trans: {
            required,
          },
        },
      },
    };
  },
  methods: {
    getFile(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];

        if (this.validaArchivo(file)) {
          this.fileDoc = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.documento = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    getIndex(page = 1) {
      this.cargando = true;

      axios
        .get("/api/tep/preliquidacionesInternos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.preliquidaciones = response.data.paginate;
          this.data_calendario = response.data.data;
          if (this.$refs.TepPreliquidacionInternoTabla) {
            this.$refs.TepPreliquidacionInternoTabla.preliquidaciones = this.preliquidaciones;
          }
          this.getCalendarData();
        });
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
    /***** Funciones para los campos del filtro */
    getTransportadora() {
      let me = this;
      axios
        .get("api/admin/empresas/listaTep?estado=1")
        .then(function(response) {
          me.listasForms.transportadoras = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/98").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.empresa_id = this.transportadora.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTipoVehiculo() {
      if (this.tipo_vehiculo != null) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      } else {
        this.filtros.tipo_vehiculo_id = null;
      }
    },

    getVehiculos() {
      let me = this;
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        me.listasForms.vehiculos = response.data;
      });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    getConductores() {
      let me = this;
      axios.get("/api/admin/conductores/lista").then((response) => {
        me.listasForms.conductores = response.data;
      });
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    getTipoOperacion() {
      let me = this;
      axios.get("/api/lista/80").then((response) => {
        me.listasForms.tipos_operaciones = response.data;
      });
    },

    seleccionarTipoOperacion() {
      if (this.tipo_operacion != null) {
        this.filtros.tipo_operacion = this.tipo_operacion.numeracion;
      } else {
        this.filtros.tipo_operacion = null;
      }
    },

    getOperaciones() {
      let me = this;
      axios.get("/api/admin/sitios/lista").then((response) => {
        me.listasForms.operaciones = response.data;
      });
    },

    seleccionarOperacion() {
      if (this.operacion != null) {
        this.filtros.operacion_id = this.operacion.id;
      } else {
        this.filtros.operacion_id = null;
      }
    },

    getTurnos() {
      axios.get("/api/admin/turnos/lista").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },

    seleccionarTurno() {
      if (this.select_turno != null) {
        this.filtros.turno_id = this.select_turno.id;
      } else {
        this.filtros.turno_id = null;
      }
    },

    limpiar() {
      this.filtros = {
        fecha_ini: null,
        fecha_fin: null,
      };
      this.transportadora = null;
      this.tipo_vehiculo = null;
      this.vehiculo = null;
      this.conductor = null;
      this.tipo_operacion = null;
      this.operacion = null;
      this.fecha_ini = null;
      this.fecha_fin = null;
      this.getIndex();
    },
    /***** Fin - Funciones para los campos del filtro */

    /***** Funciones del calendario */
    getCalendarData() {
      if (this.data_calendario) {
        let evento = [];
        let base = [];
        this.data_calendario.forEach((preliquidacion) => {
          if (preliquidacion.tep_preliquidacion_internos.length > 0) {
            if (!base.find((p) => p.id === preliquidacion.vehiculo.id)) {
              base.push({
                id: preliquidacion.vehiculo.id,
                campo: preliquidacion.sitio.nombre,
                operacion: preliquidacion.nTipoOperacion,
                vehiculo: preliquidacion.vehiculo.placa,
              });
            }

            evento.push({
              id: preliquidacion.id,
              resourceId: preliquidacion.vehiculo.id,
              title: `${preliquidacion.turno.nombre} - ${preliquidacion.vehiculo.placa} ${preliquidacion.conductor.nombres} ${preliquidacion.conductor.apellidos} (${preliquidacion.empresa.razon_social})`,
              start: preliquidacion.fecha_ini,
              end: preliquidacion.fecha_fin,
              allDay: false,
              color: "purple",
              preliquidacion,
            });
          }
        });
        this.calendarOptions.events = evento;
        this.calendarOptions.resources = base;
      }
    },

    async verDet(clickInfo) {
      await this.getModalData(clickInfo.event.extendedProps.preliquidacion);
      this.$refs.modal_preliquidacion.click();
    },
    /***** Fin - Funciones del calendario */

    /***** Funciones para cargar los datos del modal */
    getModalData(preliquidacion) {
      this.modalTitle = `Detalle Preliquidación`;
      this.modalData = null;
      this.modalData = preliquidacion;

      this.resetFormFirmas(preliquidacion.tep_preliquidacion_internos[0]);
      this.getFormConciliacion(preliquidacion);
    },

    resetFormFirmas(tep_preliquidacion_internos) {
      this.firmas = {
        fronteraForm: {
          id: tep_preliquidacion_internos.id,
          obs_firma_frontera: null,
          pass: null,

          re_pass: null,
        },
        transportadoraForm: {
          id: tep_preliquidacion_internos.id,
          obs_firma_trans: null,
          pass: null,
          re_pass: null,
        },
      };
      this.documento = tep_preliquidacion_internos.documento ? 
        this.uri_docs + tep_preliquidacion_internos.documento: 
        null;
    },

    async validaConciliacion(preliquidacion) {
      const data = await preliquidacion.tep_preliquidacion_internos[0];
      this.form.conc_horas_trabajadas = this.form.horas_trabajadas;
      this.form.conc_km_recorridos = this.form.km_recorridos;
      if (parseFloat(this.form.conc_horas_extra) > 0) {
        let horas = 0;
        let turno = await this.listasForms.turnos.find(
          (t) => t.id === preliquidacion.turno_id
        );
        horas = turno.dia_turno[0].cant_horas;

        this.form.conc_horas_trabajadas =
          horas + parseFloat(this.form.conc_horas_extra);
      } else {
        let turno = await this.listasForms.turnos.find(
          (t) => t.id === preliquidacion.turno_id
        );
        let horas = turno.dia_turno[0].cant_horas;
        if (data.horas_trabajadas > horas) {
          this.form.conc_horas_trabajadas = horas;
        } else {
          this.form.conc_horas_trabajadas = data.horas_trabajadas;
        }
      }

      if (parseFloat(this.form.conc_km_add) > 0) {
        this.form.conc_km_recorridos =
          parseFloat(preliquidacion.tep_det_tarifa_interno.bolsa_km) +
          parseFloat(this.form.conc_km_add);
      } else {
        if (data.km_add > preliquidacion.tep_det_tarifa_interno.bolsa_km) {
          this.form.conc_km_recorridos =
            preliquidacion.tep_det_tarifa_interno.bolsa_km;
        } else {
          this.form.conc_km_recorridos = data.km_recorridos;
        }
      }

      // Se valida el valor a guardar del pernocte
      let falg_pernocte = 0;
      let costo_pernocte = 0;
      if (this.form.pernocte) {
        falg_pernocte = 1;
        costo_pernocte =
          falg_pernocte *
          data.det_tarifa_interno.tarifa_interno.contrato.pernocte;
      }
      this.form.pernocte = falg_pernocte;
      this.form.costo_pernocte = costo_pernocte;

      await this.save();
    },

    getFormConciliacion(preliquidacion) {
      const data = preliquidacion.tep_preliquidacion_internos[0];

      //   Se verifica si ya tiene una conciliación anterior
      let conc_horas_extra = data.conc_horas_extra;
      if (!data.conc_horas_extra) {
        conc_horas_extra = data.horas_extra;
      }
      let conc_costo_horas_extra = data.conc_costo_horas_extra;
      if (!data.conc_costo_horas_extra) {
        conc_costo_horas_extra = data.costo_horas_extra;
      }
      let conc_km_add = data.conc_km_add;
      if (!data.conc_km_add) {
        conc_km_add = data.km_add;
      }
      let conc_costo_km_add = data.conc_costo_km_add;
      if (!data.conc_costo_km_add) {
        conc_costo_km_add = data.costo_km_add;
      }

      let costo_pernocte = data.costo_pernocte;
      if (!data.costo_pernocte) {
        costo_pernocte = data.costo_pernocte;
      }

      this.form = {
        id: data.id,
        val_km_add: data.val_km_add,
        val_hora_extra: data.val_hora_extra,
        horas_extra: data.horas_extra,
        costo_horas_extra: data.costo_horas_extra,
        conc_horas_trabajadas:
          data.conc_horas_trabajadas == null
            ? data.horas_trabajadas
            : data.conc_horas_trabajadas,
        conc_km_recorridos:
          data.conc_km_recorridos == null
            ? data.km_recorridos
            : data.conc_km_recorridos,
        km_add: data.km_add,
        costo_km_add: data.costo_km_add,
        conc_horas_extra,
        conc_costo_horas_extra,
        conc_km_add,
        conc_costo_km_add,
        pernocte: data.pernocte == null ? false : data.pernocte,
        costo_pernocte,
        adicional: data.adicional == null ? false : data.adicional,
      };
    },
    /***** Fin - Funciones para cargar los datos del modal */

    /***** Funciones de validaciones de los formularios */
    validaFechasFiltro() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        this.validaFechas(this.filtros.fecha_ini, this.filtros.fecha_fin);
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    /***** Fin - Funciones de validaciones de los formularios */

    /***** Funciones para calcular preliquidaciones */
    calcularPreLiquidacion(tep_turno_id, pagina) {
      this.cargando = true;
      const params = {
        tep_turno_id,
      };
      axios
        .put(
          "/api/tep/preliquidacionesInternos/calcularPreLiquidacionInterno",
          params
        )
        .then((response) => {
          this.cargando = false;
          if (response.data.msg) {
            this.$swal({
              icon: "success",
              title: response.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex(pagina);
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    /***** Fin - Funciones para calcular preliquidaciones */

    /**** Funciones para cargar los datos de la trazabilidad */
    getTraza() {
      if (this.preliquidacion_id != this.modalData.id) {
        this.cargando = true;
        this.preliquidacion_id = this.modalData.id;
        const params = {
          vehiculo_id: this.modalData.vehiculo_id,
          fecha_inicial: this.modalData.fecha_ini,
          fecha_final: this.modalData.fecha_fin,
        };

        axios
          .get("/api/tep/preliquidacionesInternos/trazabilidad", {
            params,
          })
          .then((response) => {
            if (!response.data.msg_error) {
              this.traza_viaje = response.data.traza;
              const datos_mapa = response.data.datos_mapa;
              this.center = {
                lat: parseFloat(datos_mapa.centro_mapa.latitud),
                lng: parseFloat(datos_mapa.centro_mapa.longitud),
              };
              this.zoom = datos_mapa.zoom;
              this.mensaje_error = null;
              this.getMapa();
            } else {
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.center = {
                lat: parseFloat(this.modalData.lat_ini_vehiculo),
                lng: parseFloat(this.modalData.lon_ini_vehiculo),
              };
              this.mensaje_error = response.data.msg_error;
              this.traza_viaje = null;
              this.zoom = 16;
              this.cargando = false;
            }
          });
      }
    },

    async showGeocercas() {
      this.cargando = true;
      await axios
        .get("/api/tep/mapas/showGeocercasZona")
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.push({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });

              this.poligonos.push({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                // color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.push({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                info: this.locations.geocerca_cir[j]["ventana"],
              });
            }
          }
        })
        .catch(function(error) {
          this.cargando = false;
        });
    },

    async getMapa() {
      const origen = {
        lat: parseFloat(this.modalData.lat_ini_vehiculo),
        lng: parseFloat(this.modalData.lon_ini_vehiculo),
      };
      const destino = {
        lat: parseFloat(this.modalData.lat_fin_vehiculo),
        lng: parseFloat(this.modalData.lon_fin_vehiculo),
      };
      await this.addMarkers(origen, destino);
    },

    async addMarkers(origen, destino) {
      await this.limpiarMapa();
      await this.traza_viaje.forEach((traza) => {
        const punto = {
          lat: parseFloat(traza.dblLatitud),
          lng: parseFloat(traza.dblLongitud),
        };
        const info = `
          <div class="wrapper">
            <h3 style="font-size: 15px" class="text-center">Vehículo: ${traza.strPlaca}</h3>
            <hr />
            <b>Fecha reporte:</b> ${traza.dteFechaReporte}<br />
            <b>Posicion:</b> ${traza.dblLatitud}, ${traza.dblLongitud} <br />
            <b>Velocidad:</b> ${traza.intVelocidadInstantanea} Km/h<br />
            <b>Transportadora:</b> ${traza.strDocTransportadora}<br />
            <b>Distancia Recorrida:</b> ${traza.intDistanciaRecorrida}
          </div>
        `;
        this.markers_viaje.push({
          id: traza.id,
          position: punto,
          info,
          icon: "/img/icon_viaje.png",
        });
      });

      // Datos del origen
      const info_origen = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Origen: ${this.modalData.sitio.nombre}</h3>
          <hr />
          <b>Iniciado:</b> ${this.modalData.fecha_ini}<br />
        </div>
      `;
      await this.markers.push({
        position: origen,
        info: info_origen,
        icon: "/img/icon_punto_g_ok.png",
      });

      // Datos del destino
      const info_destino = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Origen: ${this.modalData.sitio.nombre}</h3>
          <hr />
          <b>Iniciado:</b> ${this.modalData.fecha_fin}<br />
        </div>
      `;

      await this.markers.push({
        position: destino,
        info: info_destino,
        icon: "/img/icon_punto_g.png",
      });

      this.cargando = false;
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    limpiarMapa() {
      this.markers = [];
      this.markers_viaje = [];
    },
    /**** Fin - Funciones para cargar los datos de la trazabilidad */

    /***** Funciones para el formulario de conciliaciones */
    calcCostoHorasExtra() {
      this.form.conc_costo_horas_extra =
        this.form.val_hora_extra * this.form.conc_horas_extra;
    },

    calcCostoKmAdicionales() {
      if (this.modalData.tep_det_tarifa_interno.acumula_km == 0) {
        this.form.conc_costo_km_add =
          this.form.val_km_add * this.form.conc_km_add;
      } else {
        this.form.conc_costo_km_add = 0;
      }
    },
    /***** Fin - Funciones para el formulario de conciliaciones */

    /***** Funciones para firmar */
    saveFirma() {
      this.cargando = true;
      let me = this;
      if (
        !this.$v.firmas.transportadoraForm.$invalid ||
        !this.$v.firmas.fronteraForm.$invalid
      ) {
        let pass = null;
        let url = null;
        let data = {};

        // Se valida si la firma es de Frontera
        if (!this.$v.firmas.fronteraForm.$invalid) {
          url = "/api/tep/preliquidacionesInternos/saveFirmaFrontera";
          pass = this.firmas.fronteraForm.pass;
          data = {
            id: this.firmas.fronteraForm.id,
            obs_firma_frontera: this.firmas.fronteraForm.obs_firma_frontera,
            user_firma_frontera_id: this.$store.getters.getUser.id,
            loginAzure: this.$store.getters.getAuthAzure,
          };
          if (this.fileDoc) {
            let formData = new FormData();
            formData.append("firma_id", this.firmas.fronteraForm.id);
            formData.append("Observaciones", this.firmas.fronteraForm.obs_firma_frontera);
            formData.append("file", this.fileDoc);

            axios
              .post("/api/tep/preliquidacionesInternos/documentos", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                if (res.data.msg) {
                  this.$swal({
                    icon: "succes",
                    title: res.data.msg,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                  });
                }
                this.$parent.cargando = false;
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.$parent.cargando = false;
              });
          }
        }

        // Se valida si la firma es de la transportadora
        if (!this.$v.firmas.transportadoraForm.$invalid) {
          url = "/api/tep/preliquidacionesInternos/saveFirmaTransportadora";
          pass = this.firmas.transportadoraForm.pass;
          data = {
            id: this.firmas.transportadoraForm.id,
            obs_firma_trans: this.firmas.transportadoraForm.obs_firma_trans,
            user_firma_trans_id: this.$store.getters.getUser.id,
          };
        }
        let form = { data, pass };

        axios({
          method: "PUT",
          url,
          data: form,
        })
          .then((response) => {
            if (!response.data.user_error) {
              this.cargando = false;
              me.getIndex();
              this.$refs.closeModal.click();

              me.$swal({
                icon: "success",
                title: "La preliquidación fue autorizada exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              me.$swal({
                icon: "error",
                title: response.data.user_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Función para guardar las conciliaciones
    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.adicional = this.form.adicional ? 1 : null;
        await axios({
          method: "PUT",
          url: "/api/tep/preliquidacionesInternos/saveConciliacion",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.actualPage = this.preliquidaciones.current_page;
            this.getIndex(this.actualPage);
            //falta poner todos los datos del modal data
            this.modalData.tep_preliquidacion_internos[0].val_km_add = this.form.val_km_add;
            this.modalData.tep_preliquidacion_internos[0].val_hora_extra = this.form.val_hora_extra;
            this.modalData.tep_preliquidacion_internos[0].horas_extra = this.form.horas_extra;
            this.modalData.tep_preliquidacion_internos[0].costo_horas_extra = this.form.costo_horas_extra;
            this.modalData.tep_preliquidacion_internos[0].conc_horas_trabajadas = this.form.conc_horas_trabajadas;
            this.modalData.tep_preliquidacion_internos[0].conc_km_recorridos = this.form.conc_km_recorridos;
            this.modalData.tep_preliquidacion_internos[0].km_add = this.form.km_add;
            this.modalData.tep_preliquidacion_internos[0].costo_km_add = this.form.costo_km_add;
            this.modalData.tep_preliquidacion_internos[0].conc_horas_extra = this.form.conc_horas_extra;
            this.modalData.tep_preliquidacion_internos[0].conc_costo_horas_extra = this.form.conc_costo_horas_extra;
            this.modalData.tep_preliquidacion_internos[0].conc_km_add = this.form.conc_km_add;
            this.modalData.tep_preliquidacion_internos[0].conc_costo_km_add = this.form.conc_costo_km_add;
            this.$swal({
              icon: "success",
              title: "La guardó exitosamente la conciliación...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    verPDF(inspeccion_id) {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/tep/inspecciones/pdf/" + inspeccion_id,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    setCostoPernocte(p, costo) {
      if (p) {
        this.form.costo_pernocte = costo;
      } else {
        this.form.costo_pernocte = 0;
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getTransportadora();
    this.getTiposVehiculos();
    this.getVehiculos();
    this.getConductores();
    this.getTipoOperacion();
    this.getOperaciones();
    this.getEstados();
    this.getTurnos();
  },
};
</script>
