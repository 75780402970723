<template>
  <div class="hold-transition">
    <div class="card-body table-responsive p-0 mb-2">
      <table
        class="
          table table-bordered table-striped table-hover
          text-nowrap
          table-sm
        "
        style="font-size: 12px"
      >
        <thead class="thead-dark">
          <tr class="text-center">
            <th>Turno</th>
            <th>Estado</th>
            <th>Turno</th>
            <th>Fecha</th>
            <th>Vehículo</th>
            <th>Conductor</th>
            <th>Transportadora</th>
            <th>Operación</th>
            <th>Sitio</th>
            <th>Tipo Vehículo</th>
            <th>Horas Trabajadas</th>
            <th>Km Recorridos</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="preliquidaciones">
          <tr
            v-for="preliquidacion in preliquidaciones.data"
            :key="preliquidacion.id"
          >
            <td class="text-center">
              {{ preliquidacion.id }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="
                  !preliquidacion.tep_preliquidacion_internos[0]
                    ? 'bg-danger'
                    : preliquidacion.tep_preliquidacion_internos[0].estado == 1
                    ? 'bg-info'
                    : preliquidacion.tep_preliquidacion_internos[0].estado == 2
                    ? 'bg-navy'
                    : preliquidacion.tep_preliquidacion_internos[0].estado == 3
                    ? 'bg-success'
                    : 'badge-gray'
                "
              >
                {{
                  preliquidacion.tep_preliquidacion_internos[0]
                    ? preliquidacion.tep_preliquidacion_internos[0].nEstado
                    : "No liquidado"
                }}
              </span>
            </td>
            <td>
              {{ preliquidacion.turno ? preliquidacion.turno.nombre : "" }}
            </td>
            <td>{{ preliquidacion.fecha }}</td>
            <td>
              {{ preliquidacion.vehiculo ? preliquidacion.vehiculo.placa : "" }}
            </td>
            <td>
              {{ preliquidacion.conductor ? `${preliquidacion.conductor.nombres} ${preliquidacion.conductor.apellidos}` : "" }}
            </td>
            <td>
              {{
                preliquidacion.empresa
                  ? preliquidacion.empresa.razon_social
                  : ""
              }}
            </td>
            <td>{{ preliquidacion.nTipoOperacion }}</td>
            <td>{{ preliquidacion.sitio.nombre }}</td>
            <td>
              {{
                preliquidacion.vehiculo
                  ? preliquidacion.vehiculo.tipo_vehiculo.nombre
                  : ""
              }}
            </td>
            <td class="text-center">
              <div v-if="preliquidacion.tep_preliquidacion_internos.length > 0">
                <div
                  v-if="
                    preliquidacion.tep_preliquidacion_internos[0]
                      .conc_horas_trabajadas > 0
                  "
                >
                  {{
                    preliquidacion.tep_preliquidacion_internos[0]
                      .conc_horas_trabajadas
                  }}
                </div>
                <div v-else>
                  {{
                    preliquidacion.tep_preliquidacion_internos[0]
                      .horas_trabajadas
                  }}
                </div>
              </div>
            </td>
            <td class="text-center">
              <div v-if="preliquidacion.tep_preliquidacion_internos.length > 0">
                <div
                  v-if="
                    preliquidacion.tep_preliquidacion_internos[0]
                      .conc_km_recorridos > 0
                  "
                >
                  {{
                    preliquidacion.tep_preliquidacion_internos[0]
                      .conc_km_recorridos
                  }}
                </div>
                <div v-else>
                  {{
                    preliquidacion.tep_preliquidacion_internos[0].km_recorridos
                  }}
                </div>
              </div>
            </td>
            <td class="text-right">
              <div class="btn-group float-right">
                <button
                  type="button"
                  class="btn bg-navy btn-sm"
                  data-toggle="modal"
                  data-target="#Modal_preliquidacion"
                  v-if="
                    preliquidacion.tep_preliquidacion_internos.length > 0 &&
                    preliquidacion.tep_det_tarifa_interno_id
                  "
                  @click="$parent.getModalData(preliquidacion)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn bg-primary btn-sm"
                  v-if="
                    $store.getters.can(
                      'tep.preliquidacionesInternos.calcularPreLiquidacionInterno'
                    ) &&
                    ((preliquidacion.tep_preliquidacion_internos.length > 0 &&
                      (preliquidacion.tep_preliquidacion_internos[0].estado ==
                        1 ||
                        preliquidacion.tep_preliquidacion_internos[0].estado ==
                          2)) ||
                      preliquidacion.tep_preliquidacion_internos.length == 0)
                  "
                  @click="
                    $parent.calcularPreLiquidacion(
                      preliquidacion.id,
                      preliquidaciones.current_page
                    )
                  "
                >
                  <i class="fas fa-sync-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <div class="float-left" v-if="preliquidaciones.total">
        <p>
          Mostrando del <b>{{ preliquidaciones.from }}</b> al
          <b>{{ preliquidaciones.to }}</b> de un total:
          <b>{{ preliquidaciones.total }}</b> Registros
        </p>
      </div>
      <div class="float-left" v-else>
        <p>
          <span class="badge badge-danger">
            No hay registros para mostrar
          </span>
        </p>
      </div>
      <pagination
        :data="preliquidaciones"
        @pagination-change-page="$parent.getIndex"
        :limit="5"
        align="right"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";

export default {
  name: "TepPreliquidacionInternoTabla",
  components: { pagination },
  data() {
    return {
      preliquidaciones: {},
    };
  },
  methods: {
    getIndex() {
      if (this.$parent.preliquidaciones.data) {
        this.preliquidaciones = this.$parent.preliquidaciones;
      }
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
